import React from 'react'
import RouterRoot from '@/router'
import Nav from '@/pages/nav'
import Bottom from "@/pages/Bottom";
import {useLocation} from "react-router-dom";
import '@/styles/App.scss';
import '@/styles/mediaScreen.scss';

export default function App(props) {
  let location = useLocation();
  let isHomepage = location.pathname == "/"
  return <>
    <Nav/>
    <div id='mainContentContainer' className={`${isHomepage && "homepage"}`}>
      <RouterRoot/>
    </div>
    <Bottom className={`${isHomepage && "homepage"}`}/>
  </>
}
