import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  show: {header: true, nav: false}
}

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHeaderShow: (state, action) => {
      state.show = {...state.show, header: action.payload};
    },
    setNavShow: (state, action) => {
      state.show = {...state.show, nav: action.payload};
    },
  },
})

